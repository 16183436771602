import React, { useContext, useState } from 'react';
import { BasketContexts } from './BasketContexts';

const EditItemsModels = () => {

const { editModel, setEditModel, editFun, editItem, setEditItem, editFunDone } = useContext(BasketContexts)

console.log("editItem--------->",editItem)
const [qty,setQty] = useState(editItem.quantity)
const [specialNote,setSpecialNote] = useState(editItem.specialNote)

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        {/* <h3>{item.name}</h3> */}
        <h3>Edit {editItem.name} Order</h3>
        <img
          src={editItem.image || 'path/to/default-image.jpg'}
        //   alt={item.name}
          style={modalStyles.image}
        />
        {/* <p>{selecteditems.description}</p>
        <p style={modalStyles.price}>${selecteditems.price}</p> */}
         <h3 style={modalStyles.title}>{editItem.name}</h3>
                <p style={modalStyles.description}>{editItem.description}</p>
                <p style={modalStyles.price}>Rs. {editItem.price}</p>
        <label>
          Quantity:
          <input
            type="number"
            // value={selecteditems.quantity}
            // onChange={(e) => setSelectedItems({...selecteditems, quantity : e.target.value})}
            // onChange={(e) => setQuantity(e.target.value)}
            value={qty}
            // onChange={(e) => setSelectedItems({...selecteditems, quantity : e.target.value})}
            onChange={(e) => setQty(e.target.value)}
            style={modalStyles.input}
            />
        </label>
        <label>
          Special Note:
          <input
            type="text"
            // value={selecteditems.advice}
            // onChange={(e) => setSelectedItems({...selecteditems, advice : e.target.value})}
            value={specialNote}
            onChange={(e) => setSpecialNote(e.target.value)}
            style={modalStyles.input}
          />
        </label>
        {/* <button onClick={()=>handleEdit(selecteditems.id,selecteditems, setEditModel)} style={modalStyles.button}>Done</button>
        <button onClick={handleEditModelClose} style={modalStyles.button}>Cancel</button> */}
        <button onClick={()=>editFunDone(editItem, qty, specialNote)} style={modalStyles.button} >Done</button>
        <button onClick={editFun}  style={modalStyles.button}>Cancel</button>
      </div>
    </div>
  );
};

const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      // backgroundColor: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      
    },
    modal: {
      background: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      textAlign: 'center',
      width: '300px',
    },
    image: {
      width: '100%',
      height: '150px',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    price: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    input: {
      display: 'block',
      margin: '10px auto',
      padding: '8px',
      width: '80%',
    },
    button: {
      margin: '10px',
      padding: '10px',
      backgroundColor: '#007BFF',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: '700',
      margin: '10px 0',
      color: '#333',
  },
  description: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '10px',
},
price: {
    fontWeight: '600',
    fontSize: '1.25rem',
    color: '#007BFF',
    // marginBottom: '15px',
    // fontSize: '0.9rem',
    color: '#7d1a74',
    fontWeight: '800',
},
  };

export default EditItemsModels;
