// import React from 'react';
// import QRCode from 'qrcode.react';

// const QRCodeGenerator = () => {
//   const appUrl = "http://192.168.1.6:3000/"; // Replace with your app URL

//   return (
//     <div>
//       <h2>Scan this QR code to access the app:</h2>
//       <QRCode 
//         value={appUrl}
//         size={256} // Size of the QR code
//         fgColor="#000000" // Color of the QR code
//       />
//     </div>
//   );
// };

// export default QRCodeGenerator;


import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeGenerator = () => {
   // Create a string with family members' names

   //  const familyMembers =   "http://192.168.1.10:3000/CategoryLists?hotelId=123&hotelName=DineDash&tableNumber=T-01";
   const familyMembers =   "https://dinedash.yes-pos.in/?hotelId=123&hotelName=DineDash&tableNumber=Table-2";

  //  https://dinedash.yes-pos.in/
  //  const familyMembers = [
  //   {
  //     id : 1,
  //     familyName : 'shubham',
  //     members : 10
  //   },
  //   {
  //     id : 2,
  //     familyName : 'shakywar',
  //     members :20
  //   },
  //  ]

   return (
     <div>
       {/* <h2>Scan this QR Code to see family members' names:</h2> */}
     <div style={{border: '0px solid red',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'200px'}}>
     <QRCode value={familyMembers} size={200} />
     </div>
     <div style={{border: '0px solid red',display:'flex',flexDirection:'row',justifyContent:'center'}}>

       <h2>Scan this QR Code Table-2</h2>
     </div>
     </div>
   );
 };

export default QRCodeGenerator;

// import React from 'react';
// import QRCode from 'qrcode.react';

// const QRCodeGenerator = () => {
//   // Your base URL
//   const baseUrl = "http://192.168.1.6:3000/dinetable";

//   // Your family members data
//   const familyMembers = [
//     {
//       id: 1,
//       familyName: 'shubham',
//       members: 10,
//     },
//     {
//       id: 2,
//       familyName: 'shakywar',
//       members: 20,
//     },
//   ];

//   // Combine URL and data into a single object
//   const data = {

//     url: `${baseUrl}?hotelId=123&hotelName=DineDash`,
//     familyMembers: familyMembers,

//   };


//   // Convert the data object to a JSON string
//   const dataString = JSON.stringify(data);

//   console.log("dataString------------>",dataString)

//   return (
//     <div>
//       <h2>Scan this QR Code to view details:</h2>
//       <QRCode value={dataString} size={200} />
//     </div>
//   );
// };

// export default QRCodeGenerator;
