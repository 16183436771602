// // FoodItemModal.js
// import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
// import { BasketContexts } from './BasketContexts';

// // const FoodItemsModals = ({ item, onClose, onAddToBasket, setBasket }) => {
// const FoodItemsModals = ({selectedItem, setFoodItemsModel}) => {
//     const {name, price, description, image} = selectedItem 
//     console.log("name-------->",name)
//     const {foodItemDone} = useContext(BasketContexts)
//     const [qty,setQty] = useState(0)
//     const [special,setSpecial] = useState('')
    
//     const [doneItems,setDoneItems] = useState('')
// console.log("doneItems------------------------------>",doneItems)
//     console.log("qty-------->",qty)
//     console.log("special-------->",special)

// //   const [quantity, setQuantity] = useState('');
// //   const [advice, setAdvice] = useState('');

// //   console.log('item------------>',item)
// //   console.log('setBasket------------>',setBasket)




// //   const handleDone = () => {
// //     console.log('ITEM------------>',item)
// //     console.log('quantity------------>',quantity)
// //     console.log('advice------------>',advice)
// //     if(!quantity){
// // console.log("no")
// // alert('Please choose a quantity. ')
// //     }else{
// //       console.log("yes")
// //       onAddToBasket(item, quantity, advice);
// //       onClose();
// //       // alert('Your item is now included in the backet. ')
// //       notify()
// //     }
// //   };


// //   const notify = () => {
// //     console.log("ca")
// //     toast.success('Your item is now included in the backet. ');
// //   };



// const handleDone = () => {

//     console.log("selectedItem--------------------------------------_>",selectedItem)
//     setDoneItems({...selectedItem, quantity : qty, specialNote : special})

// }


// const onClose = () =>{
//     setFoodItemsModel(false)
// }

//   return (
//     <div style={modalStyles.overlay}>
//       <div style={modalStyles.modal}>
//         {/* <h3 style={modalStyles.title}>{item.name}</h3> */}
//         <img
//           src={image || 'path/to/default-image.jpg'}
//         //   alt={item.name}
//           style={modalStyles.image}
//         />
//         <p style={modalStyles.description}>{description}</p>
//         <p style={modalStyles.price}>Rs. {price}</p>
//         <label style={modalStyles.label}>
//           Quantity:
//           <input
//             type="number"
//             value={qty}
//             onChange={(e) => setQty(e.target.value)}
//             style={modalStyles.input}
//             placeholder="Enter quantity"
//           />
//         </label>
//         <label style={modalStyles.label}>
//           Special Note:
//           <input
//             type="text"
//             value={special}
//             onChange={(e) => setSpecial(e.target.value)}
//             style={modalStyles.input}
//             placeholder="Add a special note"
//           />
//         </label>
//         <div style={modalStyles.buttonContainer}>
//           <button onClick={()=>foodItemDone(selectedItem, qty, special, setFoodItemsModel)} style={modalStyles.buttonPrimary}>Done</button>
//           <button onClick={onClose} style={modalStyles.buttonSecondary}>Cancel</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const modalStyles = {
//   overlay: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0,0,0,0.6)',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modal: {
//     background: 'white',
//     padding: '30px',
//     borderRadius: '10px',
//     boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//     textAlign: 'center',
//     width: '350px',
//     maxWidth: '90%',
//   },
//   title: {
//     fontSize: '24px',
//     fontWeight: 'bold',
//     marginBottom: '15px',
//     color: '#333',
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//     borderRadius: '10px',
//     marginBottom: '15px',
//   },
//   description: {
//     fontSize: '16px',
//     color: '#666',
//     marginBottom: '15px',
//   },
//   price: {
//     fontWeight: 'bold',
//     fontSize: '20px',
//     color: '#007BFF',
//     marginBottom: '20px',
//   },
//   label: {
//     display: 'block',
//     textAlign: 'left',
//     marginBottom: '10px',
//     fontSize: '14px',
//     color: '#333',
//   },
//   input: {
//     display: 'block',
//     width: '100%',
//     padding: '10px',
//     marginBottom: '20px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//     fontSize: '16px',
//     boxSizing: 'border-box',
//   },
//   buttonContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   buttonPrimary: {
//     padding: '10px 20px',
//     backgroundColor: '#28a745',
//     color: 'white',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '16px',
//     flex: 1,
//     marginRight: '10px',
//   },
//   buttonSecondary: {
//     padding: '10px 20px',
//     backgroundColor: '#6c757d',
//     color: 'white',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '16px',
//     flex: 1,
//   },
// };

// // FoodItemModal.propTypes = {
// //   item: PropTypes.object.isRequired,
// //   onClose: PropTypes.func.isRequired,
// //   onAddToBasket: PropTypes.func.isRequired,
// // };

// export default FoodItemsModals;


import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { BasketContexts } from './BasketContexts';

const FoodItemsModals = ({ selectedItem, setFoodItemsModel }) => {
    console.log("selectedItem--------->",selectedItem)
    const { name, iteM_RATE, description, image } = selectedItem;
    const { foodItemDone } = useContext(BasketContexts);
    const [qty, setQty] = useState('');
    const [special, setSpecial] = useState('');
    const [doneItems, setDoneItems] = useState('');

    const handleDone = () => {
console.log("selectedItem------->",selectedItem)
const filterSelectedItem = {...selectedItem, iteM_TRN_QTY_ID: selectedItem.iteM_QTY_ID, quantity: Number(qty), rate: selectedItem.iteM_RATE, tablE_ID: 32, remark : special}
console.log("filterSelectedItem------->",filterSelectedItem)


      if(!qty){
        toast.error('Please choose a quantity.');
      }else{
        
        setDoneItems(filterSelectedItem);
        foodItemDone(filterSelectedItem, setFoodItemsModel);
        toast.success('Your item is now included in the backet. ');
      }





    };

    const onClose = () => {
        setFoodItemsModel(false);
    };

    return (
        <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
                {/* <img
                    src={image || 'path/to/default-image.jpg'}
                    alt={name}
                    style={modalStyles.image}
                /> */}
                <h3 style={modalStyles.title}>{name}</h3>
                <p style={modalStyles.description}>{description}</p>
                <p style={modalStyles.price}>Rs. {iteM_RATE}</p>
                <label style={modalStyles.label}>
                    Quantity:
                    <input
                        type="number"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        style={modalStyles.input}
                        placeholder="Enter quantity"
                    />
                </label>
                <label style={modalStyles.label}>
                    Special Note:
                    <input
                        type="text"
                        value={special}
                        onChange={(e) => setSpecial(e.target.value)}
                        style={modalStyles.input}
                        placeholder="Add a special note"
                    />
                </label>
                <div style={modalStyles.buttonContainer}>
                    <button onClick={handleDone} style={modalStyles.buttonPrimary}>
                        Done
                    </button>
                    <button onClick={onClose} style={modalStyles.buttonSecondary}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};
const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        padding: '20px', // To avoid content touching the edges on small screens
        boxSizing: 'border-box',
    },
    modal: {
        background: '#fff',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        width: '100%',
        maxWidth: '500px',
        transition: 'transform 0.3s ease-in-out',
        transform: 'scale(1)',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: '700',
        margin: '10px 0',
        color: '#333',
    },
    image: {
        width: '100%',
        height: 'auto', // Change to auto for responsiveness
        maxHeight: '300px',
        objectFit: 'cover',
        borderRadius: '8px',
        marginBottom: '15px',
    },
    description: {
        fontSize: '1rem',
        color: '#666',
        marginBottom: '10px',
    },
    price: {
        fontWeight: '600',
        fontSize: '1.25rem',
        color: '#007BFF',
        color: '#7d1a74',
        fontWeight: '800',
    },
    label: {
        display: 'block',
        textAlign: 'left',
        marginBottom: '10px',
        fontSize: '14px',
        color: '#333',
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        fontSize: '16px',
        boxSizing: 'border-box',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column', // Stack buttons vertically by default
        gap: '10px',
    },
    buttonPrimary: {
        padding: '12px 20px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        backgroundColor: '#7d1a74',
        width: '100%',
    },
    buttonSecondary: {
        padding: '12px 20px',
        backgroundColor: '#6c757d',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        width: '100%',
    },
    '@media (min-width: 768px)': {
        buttonContainer: {
            flexDirection: 'row', // Align buttons horizontally on larger screens
        },
        buttonPrimary: {
            flex: 1,
            marginRight: '10px',
        },
        buttonSecondary: {
            flex: 1,
        },
    },
    '@media (min-width: 1024px)': {
        modal: {
            maxWidth: '600px', // Increase the width on larger screens
        },
    },
};

FoodItemsModals.propTypes = {
    selectedItem: PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        description: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    setFoodItemsModel: PropTypes.func.isRequired,
};

export default FoodItemsModals;







// "result": {
//         "data": [
//             {
//                 "relation_pid": 52,
//                 "merchant_pid": 19,
//                 "merchant_name": "Meetanshi",
//                 "outlet_group_id": "outlet_group_d82641b5a2a941",
//                 "outlet_pid": 10,
//                 "outlet_id": "outlet_f4a7f40fd83b40",
//                 "outlet_name": "Celebrations The Food Junction",
//                 "outlet_owner_pid": 19,
//                 "outlet_owner_name": "Meetanshi",
//                 "outlet_owner_phone": "8962626860",
//                 "outlet_owner_email": "meetanshi1010@gmail.com",
//                 "is_outlet_activated": false,
//                 "is_outlet_suspended": false,
//                 "outlet_suspension_reason": null,
//                 "is_outlet_temporarily_closed": false,
//                 "outlet_temporarily_closed_reason": null,
//                 "is_outlet_deleted": false,
//                 "outlet_deletion_reason": null,
//                 "profile_url": "https://storage.googleapis.com/orlay-staging-storage-bucket/outlets-storage/outlet-storage-92684bdca3ce47/gallery/0e94b88399f949fc868514a5bbda68c9.jpg",
//                 "full_address": "103 9930_spaces Managal nagar Road Bhawarkuan Indore",
//                 "latitude": 78.65656567,
//                 "longitude": 68.76767767,
//                 "is_outlet_owner": true,
//                 "role": "admin",
//                 "order_notification_ring": true,
//                 "currency": "₹",
//                 "outlet_contact_number": "8461864998",
//                 "opening_time": 11,
//                 "closing_time": 22.3,
//                 "is_online": true,
//                 "whole_day_close_start_date": "2024-03-18",
//                 "whole_day_close_end_date": "2024-03-21",
//                 "every_week_close_day": 3,
//                 "time_for_preparation": 20,
//                 "minimum_amount_order_acceptance": 51,
//                 "is_packaging_charge_opted": false,
//                 "food_type": 3,
//                 "is_deleted": null,
//                 "date_created": "2024-08-24 10:51:17",
//                 "date_modified": "2024-08-24 10:51:17"
//             }
//         ]
//     },
//     "status_code": 200
// }

// import React, { useContext, useState } from "react";
// import { StyleSheet, Text, View, TouchableOpacity, FlatList } from 'react-native'
// import Modal from "react-native-modal";
// import GlobalStyle from "../../assets/css/style"
// import Colors from "../../assets/css/colors"
// import Icon from "./icon";
// import { AuthContext } from "../context";

// interface Outlet {
//     id: string;
//     address: string;
//     name: string;
//     status: boolean;
// }

// interface YourOutletsProps {
//     // Define props here if any
//     state: any
// }

// export const YourOutlet: React.FC<YourOutletsProps> = ({ state }: YourOutletsProps): JSX.Element => {
//     const [yourOutletsModalVisible, setYourOutletsModalVisible] = useState(false)
//     const authData = useContext(AuthContext)
//     const OutletInfo = authData.authState.outletInfo ? authData.authState.outletInfo : null
//     //--------------------------------------------------------------------------//
//     //---------------------------- Helper functions ----------------------------//
//     //--------------------------------------------------------------------------//

//     // your Outlets ModalVisible

//     const showYourOutlets = () => {
//         setYourOutletsModalVisible(true)
//     }

//     const hideYourOutletsModal = () => {
//         setYourOutletsModalVisible(false)
//     }


//     const [data,setData] = useState(authData.authState.outletInfo[0])

//     const outletInfo=authData.authState.outletInfo[0]
//     console.log("dataaaa--------------------",outletInfo)
//     // Extract the first outlet
   
//     return (
//         <>
//             <View style={[GlobalStyle.alignItemsCenter, GlobalStyle.border10, Colors.borderGary, GlobalStyle.radiusEight, GlobalStyle.plr_3]}>
//                 <TouchableOpacity onPress={() => { showYourOutlets() }} style={[GlobalStyle.flex, GlobalStyle.p_5]}>
//                     <View style={[GlobalStyle.flexDirectionRow, GlobalStyle.flex, GlobalStyle.alignItemsCenter]}>
//                         <View style={[
//                             GlobalStyle.flexShrink, GlobalStyle.mr_5, { width: 146 }]}>
//                             <Text numberOfLines={1} style={[GlobalStyle.font_17, GlobalStyle.boldFont, Colors.primaryBlackColor]}>
//                                 {/* Hotel Shree Ras Indore */}
//                                 {outletInfo?.outlet_name || "No Outlet"}
//                             </Text>
//                             <Text numberOfLines={1} style={[GlobalStyle.font_12, GlobalStyle.semiboldFont, Colors.secondaryBlackColor]}>{outletInfo?.full_address || "No Address"}</Text>
//                         </View>
//                         <Icon name={"sort-down"} type={"FontAwesome"} style={[GlobalStyle.font_20, GlobalStyle.flexShrink, Colors.primaryBlackColor, GlobalStyle.ml_3]} />
//                     </View>
//                 </TouchableOpacity>
//             </View>
//             <YourOutletsModal yourOutletsModalVisible={yourOutletsModalVisible} hideYourOutletsModal={hideYourOutletsModal} state={state} OutletInfo={OutletInfo} />
//         </>
//     )
// }


// const YourOutletsModal = ({ yourOutletsModalVisible, hideYourOutletsModal, state, OutletInfo }: any): JSX.Element => {
//     const [propertyData, setPropertyData] = useState([
//         {
//             id: "1",
//             address: "Napier Town Main Square, Indore",
//             name: "Hotel Shree Ras Indore",
//             status: true,
//         },
//         // {
//         //     id: "2",
//         //     address: "Civil Lines, Near Royal City Road, Jabalpur",
//         //     name: "Hotel King`s palace",
//         //     status: false,
//         // },
//         // {
//         //     id: "3",
//         //     address: "789 Maple Rd, Indore",
//         //     name: "Shree Ras Restaurant",
//         //     status: false,
//         // },
//     ]);

//     const toggleStatus = (itemId: string) => {
//         setPropertyData(
//             propertyData.map((item) =>
//                 item.id === itemId
//                     ? { ...item, status: !item.status }
//                     : { ...item, status: false }
//             )
//         );
//         // hideYourOutletsModal();
//     };

//     // render
//     const renderItem = ({ item }: { item: Outlet }) => {
//         return (
//             // <TouchableOpacity style={[GlobalStyle.mlr_15, GlobalStyle.ptb_15, propertyData.length === 1 ? null : GlobalStyle.borderBottom8, Colors.borderGary, GlobalStyle.mb_10]}
//             <TouchableOpacity style={[GlobalStyle.mlr_15, GlobalStyle.ptb_15, OutletInfo.length === 1 ? null : GlobalStyle.borderBottom8, Colors.borderGary, GlobalStyle.mb_10]}
//             // onPress={() => toggleStatus(item.id)}
//             >
//                 <View style={[GlobalStyle.flexDirectionRow, GlobalStyle.alignItemsCenter]}>
//                     <View style={[GlobalStyle.flex]}>
//                         <View style={[GlobalStyle.flexShrink, GlobalStyle.mb_3]}>
//                             <Text numberOfLines={1} style={[GlobalStyle.font_17, GlobalStyle.boldFont, Colors.primaryBlackColor]}>{item?.outlet_name}
//                             </Text>
//                         </View>
//                         <View style={[GlobalStyle.flexShrink]}>
//                             <Text numberOfLines={1} style={[GlobalStyle.font_14, GlobalStyle.semiboldFont, Colors.secondaryBlackColor]}>{item.full_address}</Text>
//                         </View>
//                     </View>

//                     {/* {item.status && ( */}
//                     <Text
//                         style={[
//                             GlobalStyle.font_14,
//                             Colors.greenBackgroundColor,
//                             Colors.whiteColor,
//                             GlobalStyle.ptb_5,
//                             GlobalStyle.plr_10,
//                             GlobalStyle.radiusSeven,
//                             GlobalStyle.boldFont,
//                         ]}
//                     >
//                         Active
//                     </Text>
//                     {/* )} */}
//                 </View>
//             </TouchableOpacity>
//         )
//     }

//     return (
//         <>
//             {
//                 yourOutletsModalVisible ?
//                     <Modal
//                         isVisible={yourOutletsModalVisible}
//                         onBackdropPress={hideYourOutletsModal}
//                         onBackButtonPress={hideYourOutletsModal}
//                     >

//                         <View style={[Colors.whiteColorBackground, GlobalStyle.radiusTen, GlobalStyle.pt_10, OutletInfo.length === 1 ? null : GlobalStyle.pb_10,]} >
//                             <View style={[GlobalStyle.plr_5, GlobalStyle.mb_5, GlobalStyle.pb_10, GlobalStyle.flexDirectionRow, GlobalStyle.alignItemsCenter, GlobalStyle.justifyContentSpaceBetween, GlobalStyle.borderBottom8, Colors.borderGary]}>
//                                 <Text style={[GlobalStyle.pl_10, GlobalStyle.boldFont, GlobalStyle.font_18, Colors.primaryBlackColor]}>Your Outlets</Text>
//                                 <TouchableOpacity onPress={hideYourOutletsModal} style={[Colors.lightGrayBackgroundColor, GlobalStyle.circleRadius, GlobalStyle.p_10, GlobalStyle.alignItemsCenter, GlobalStyle.mr_5]}>
//                                     <Icon name={"close"} type={"AntDesign"} style={[GlobalStyle.font_16, Colors.secondaryBlackColor]} />
//                                 </TouchableOpacity>
//                             </View>

//                             <FlatList
//                                 // data={propertyData}
//                                 data={OutletInfo}
//                                 keyExtractor={(item) => item.outlet_pid}
//                                 renderItem={renderItem}
//                                 contentContainerStyle={[]}
//                             // refreshing={false}
//                             />

//                             {/* <View style={[GlobalStyle.plr_15]}>
//                                 <TouchableOpacity style={[GlobalStyle.alignItemsCenter, GlobalStyle.ptb_10]}>
//                                     <Text style={[Colors.royalGreenColor, GlobalStyle.boldFont, GlobalStyle.font_18]}>Manage Outlets</Text>
//                                 </TouchableOpacity>
//                             </View> */}
//                         </View >
//                     </Modal >
//                     : null
//             }
//         </>
//     );
// }

// const styles = StyleSheet.create({
//     // Your styles if any
// })





// import React, { useEffect } from 'react';
// import { Alert, Linking, BackHandler } from 'react-native'
// import RNBootSplash from "react-native-bootsplash";
// import firebaseAuth from '@react-native-firebase/auth';
// import { LoginServices } from '../services/unauthenticated/login';
// import { VerifyOTPServices } from '../services/unauthenticated/verify-otp';
// import { ResendOTPServices } from '../services/unauthenticated/resent-otp';
// import { SplashServices } from '../services/authenticated/splash';

// const initialState = {
//     loading: true,
//     authToken: null,
//     merchantInfo: null,
//     outletInfo: null
// }

// type AuthState = {
//     loading: boolean;
//     authToken: null;
//     merchantInfo: null;
//     outletInfo: null
// }

// type AuthAction = {
//     type: string;
//     payload: any
// }

// export function AuthHook() {

//     const reducer = (authState: AuthState, action: AuthAction) => {
//         switch (action.type) {
//             case 'SET_LOADING':
//                 return {
//                     ...authState,
//                     loading: action.payload,
//                 };

//             case 'SET_TOKEN':
//                 return {
//                     ...authState,
//                     authToken: action.payload,
//                 };
//             case 'REMOVE_TOKEN':
//                 return {
//                     ...authState,
//                     authToken: undefined,
//                 };
//             case 'SET_MERCHANT_INFO':
//                 return {
//                     ...authState,
//                     merchantInfo: action.payload,
//                 }
//             case 'SET_OUTLET_INFO':
//                 return {
//                     ...authState,
//                     outletInfo: action.payload,
//                 }
//             default:
//                 return authState
//         }
//     }

//     const [authState, dispatch] = React.useReducer(reducer, initialState);

//     const auth = React.useMemo(
//         () => ({

//             signIn: async (loginData: any) => {
//                 let responseData = await LoginServices.login(loginData)
//                 return responseData
//             },

//             verifyOTP: async (verificationCode: any) => {
//                 let responseData = await VerifyOTPServices.verifyOTP(verificationCode)
//                 if (responseData.status_code === 200) {
//                     let data = responseData.result;
//                     await signInIntoFirebase(data.token)
//                     setTokenAndDefaultInfo()
//                 }
//                 return responseData
//             },

//             resendOTP: async (resendOTPData: any) => {
//                 let responseData = await ResendOTPServices.resendOTP(resendOTPData)
//                 return responseData
//             },

//             logout: async () => {
//                 await firebaseAuth().signOut().then(() => console.log('User signed out!'));
//                 dispatch({ type: 'REMOVE_TOKEN', payload: null })
//             },
//             updateMerchantInfo: async (data: { name: string; email: string }) => {
//                 console.log('Saving merchant info:', data);
//                 try {

//                     dispatch({ type: 'SET_MERCHANT_INFO', payload: data });

//                 } catch (error) {
//                     console.error('Error saving merchant info:', error);
//                 }
//             },

//         }), [])
//     const setTokenAndDefaultInfo = async () => {
//         var defaultInfo = await getMerchantDefaultInfo()
//         console.log('defaultInfo==========================', defaultInfo)
//         if (defaultInfo.status_code === 200) {
//             dispatch({ type: 'SET_MERCHANT_INFO', payload: defaultInfo.result })
//         }
//         const firebaseToken = await firebaseAuth().currentUser?.getIdToken(true)
//         console.log('firebaseToken=======================>>', firebaseToken)
//         const token = { token: firebaseToken }
//         dispatch({ type: 'SET_TOKEN', payload: token })

//         // set outlet info
//         const outletInfo = await getMerchantOutletInfo()
//         console.log('outletInfo==========================', outletInfo.result.data)
//         if (outletInfo.status_code === 200) {
//             dispatch({ type: 'SET_OUTLET_INFO', payload: outletInfo.result.data })
//         }
//     }

//     // Signs in a user into Firebase using a custom token. then after passing the custom token we will get firebase token.

//     const signInIntoFirebase = (token: any) => {
//         return new Promise(resolve => {
//             firebaseAuth()
//                 .signInWithCustomToken(token)
//                 .then((res) => {
//                     console.log(res)
//                     console.log('User account created & signed in!');
//                     resolve('User account created & signed in!')
//                 })
//                 .catch(error => {
//                     console.error(error);
//                     resolve(error)
//                 });
//         })
//     }

//     useEffect(() => {
//         const init = async () => {
//             var currentUser = firebaseAuth().currentUser
//             if (currentUser) {

//                 // -------------------------------------------------------------------//
//                 // On app init we are getting the wallet info from local storage and setting 
//                 // in a state 
//                 // -------------------------------------------------------------------//

//                 await setTokenAndDefaultInfo()
//             }
//             dispatch({ type: 'SET_LOADING', payload: false })
//         };

//         init().finally(async () => {
//             await RNBootSplash.hide({ fade: true });
//         });

//     }, [])

//     const getMerchantDefaultInfo = () => {
//         return new Promise(resolve => {
//             SplashServices.getMerchantDefaultInfo()
//                 .then((response) => {
//                     resolve(response);
//                 }).catch((errors) =>
//                     resolve(errors)
//                 )
//         });
//     }

//     // get Merchant Outlet
//     const getMerchantOutletInfo = () => {
//         return new Promise(resolve => {
//             SplashServices.getMerchantOutlet()
//                 .then((response) => {
//                     resolve(response);
//                 }).catch((errors) =>
//                     resolve(errors)
//                 )
//         });
//     }

//     return { auth, authState };
// }



// authData======================= {"auth": {"logout": [Function logout], "resendOTP": [Function resendOTP], "signIn": [Function signIn], "updateMerchantInfo": [Function updateMerchantInfo], "verifyOTP": [Function verifyOTP]}, "authState": {"authToken": {"token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJjYWxsaW5nX2NvZGUiOiIrOTEiLCJlbWFpbCI6Im1lZXRhbnNoaTEwMTBAZ21haWwuY29tIiwiaXNfb25ib2FyZGluZ19jb21wbGV0ZWQiOnRydWUsImlzX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTWVldGFuc2hpIFNhdHB1dGUiLCJwaG9uZSI6Ijg5NjI2MjY4NjAiLCJ1c2VyX3BpZCI6MTksInVzZXJfdWlkIjoibWVyY2hhbnRfMzk3YWQxMjliMTVhNGEiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vb3JsYXktc3RhZ2luZyIsImF1ZCI6Im9ybGF5LXN0YWdpbmciLCJhdXRoX3RpbWUiOjE3MjQ2NjY1MzMsInVzZXJfaWQiOiJtZXJjaGFudF8zOTdhZDEyOWIxNWE0YSIsInN1YiI6Im1lcmNoYW50XzM5N2FkMTI5YjE1YTRhIiwiaWF0IjoxNzI0NjY2NTM1LCJleHAiOjE3MjQ2NzAxMzUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnt9LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.a7AAiKwA-EQjW9ZOB3ql8bXA5cPpEapKLqfh14or2rghe1dI8txxwmpNTwbxWbALMJw9S5UsOwiFy5UtKpte49BLNATYZwdkx2uao9N9T0r_BsKUCqD5CEPUMF2Xw782a0so55Wj5YJizbgO_KhDdf5z1ogkbdo-yGaQ1qc_Ouz_at93zJlrlzVQlcem-tOtKLYwNoXBIkSePH7jmsViFCBWUyIHmKolKc_s8IKvX_EDMfOaQJxaTOgfjre8SdAM_8AnfpxY235LmDxkVaI84werm3hf6PvjiEYQsz26BkZgU7RJbjuUOq7VWcDNaDCRiolt87dvGUoTtG6M4tE8wA"}, "loading": false, "merchantInfo": {"account_deletion_reason": null, "account_suspension_reason": null, "calling_code": "+91", "date_created": "2024-08-05 07:32:59", "date_modified": "2024-08-26 10:02:01", "device_id": null, "email_address": "meetanshi1010@gmail.com", "is_account_suspended": false, "is_account_verified": true, "is_deleted": false, "is_onboarding_completed": true, "phone": "8962626860", "user_name": "Meetanshi Satpute", "user_pid": 19, "user_uid": "merchant_397ad129b15a4a"}, "outletInfo": [[Object], [Object]]}}

// const outletInfo=authData.authState.outletInfo[0]
// {
//     "auth": {
//       "logout": [Function logout],
//       "resendOTP": [Function resendOTP],
//       "signIn": [Function signIn],
//       "updateMerchantInfo": [Function updateMerchantInfo],
//       "verifyOTP": [Function verifyOTP]
//     },
//     "authState": {
//       "authToken": {
//         "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJjYWxsaW5nX2NvZGUiOiIrOTEiLCJlbWFpbCI6Im1lZXRhbnNoaTEwMTBAZ21haWwuY29tIiwiaXNfb25ib2FyZGluZ19jb21wbGV0ZWQiOnRydWUsImlzX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTWVldGFuc2hpIFNhdHB1dGUiLCJwaG9uZSI6Ijg5NjI2MjY4NjAiLCJ1c2VyX3BpZCI6MTksInVzZXJfdWlkIjoibWVyY2hhbnRfMzk3YWQxMjliMTVhNGEiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vb3JsYXktc3RhZ2luZyIsImF1ZCI6Im9ybGF5LXN0YWdpbmciLCJhdXRoX3RpbWUiOjE3MjQ2NjY1MzMsInVzZXJfaWQiOiJtZXJjaGFudF8zOTdhZDEyOWIxNWE0YSIsInN1YiI6Im1lcmNoYW50XzM5N2FkMTI5YjE1YTRhIiwiaWF0IjoxNzI0NjY2NTM1LCJleHAiOjE3MjQ2NzAxMzUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnt9LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.a7AAiKwA-EQjW9ZOB3ql8bXA5cPpEapKLqfh14or2rghe1dI8txxwmpNTwbxWbALMJw9S5UsOwiFy5UtKpte49BLNATYZwdkx2uao9N9T0r_BsKUCqD5CEPUMF2Xw782a0so55Wj5YJizbgO_KhDdf5z1ogkbdo-yGaQ1qc_Ouz_at93zJlrlzVQlcem-tOtKLYwNoXBIkSePH7jmsViFCBWUyIHmKolKc_s8IKvX_EDMfOaQJxaTOgfjre8SdAM_8AnfpxY235LmDxkVaI84werm3hf6PvjiEYQsz26BkZgU7RJbjuUOq7VWcDNaDCRiolt87dvGUoTtG6M4tE8wA"
//       },
//       "loading": false,
//       "merchantInfo": {
//         "account_deletion_reason": null,
//         "account_suspension_reason": null,
//         "calling_code": "+91",
//         "date_created": "2024-08-05 07:32:59",
//         "date_modified": "2024-08-26 10:02:01",
//         "device_id": null,
//         "email_address": "meetanshi1010@gmail.com",
//         "is_account_suspended": false,
//         "is_account_verified": true,
//         "is_deleted": false,
//         "is_onboarding_completed": true,
//         "phone": "8962626860",
//         "user_name": "Meetanshi Satpute",
//         "user_pid": 19,
//         "user_uid": "merchant_397ad129b15a4a"
//       },
//       "outletInfo": [
//         {
//           // First outlet object properties
//         },
//         {
//           // Second outlet object properties
//         }
//       ]
//     }
//   }


//   const [yourOutletsModalVisible, setYourOutletsModalVisible] = useState(false)
//   const authData = useContext(AuthContext)
//   const OutletInfo = authData.authState.outletInfo ? authData.authState.outletInfo : null
//   //--------------------------------------------------------------------------//
//   //---------------------------- Helper functions ----------------------------//
//   //--------------------------------------------------------------------------//

//   // your Outlets ModalVisible

//   const showYourOutlets = () => {
//       setYourOutletsModalVisible(true)
//   }

//   const hideYourOutletsModal = () => {
//       setYourOutletsModalVisible(false)
//   }


//   const [data,setData] = useState(authData.authState.outletInfo[0])

//   const outletInfo=authData.authState.outletInfo[0]
//   console.log("dataaaa--------------------",outletInfo)
//   // Extract the first outlet
 