// import React, { useContext, useEffect, useState } from 'react';
// import EditItemsModel from './EditItemsModel';
// import { BasketContext } from './BasketContext';

// const BasketModals = ({ items, onClose, currentTable, onEditItem, onRemoveItem, handleEdit, editModel, setEditModel, handleEditModel, handleEditModelClose, selecteditems, setSelectedItems, visibleBill , setVisibleBill }) => {

//   const { basket, table, setTable, clearBasketFun, GenrateBillFun, hotelId, hotelName  } = useContext(BasketContext);

//   const [filterData, setFilterData] = useState([]);

//   useEffect(() => {
//     const filteredTables = table.filter(item => item.id === currentTable.id);
//     setFilterData(filteredTables[0].OrderDetails);
//   }, [table, currentTable]);

//   const removeItem = (id) => {
//     const newdata = filterData.filter((item) => item.id !== id);
//     const newOrderDetails = currentTable.OrderDetails.filter((item) => item.id !== id);
  
//     setTable((prevState) =>
//       prevState.map((tableItem) =>
//         tableItem.id === currentTable.id
//           ? { ...tableItem, OrderDetails: newdata }
//           : tableItem
//       )
//     );
//   };

//   return (
//     <div style={modalBackdropStyle}>
//       <div style={modalContentStyle}>
//         <button onClick={onClose} style={closeButtonStyle}>
//           Close
//         </button>

//         <div style={{ border: '0px solid red' }}>
//           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
//             <span style={modalTitleStyle}>Your Basket</span>
//           </div>

//           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: '20px 0px' }}>
//             <button
//               onClick={clearBasketFun}
//               style={clearBacket}
//             >
//               Clear Basket
//             </button>

//             <button
//               onClick={GenrateBillFun}
//               style={GenrateBill}
//             >
//               Generate Bill
//             </button>
//           </div>
//         </div>

//         <div style={basketItemsStyle}>
//           {filterData.length ? (
//             filterData.map((item) => (
//               <div key={item.id} style={basketItemStyle}>
//                 <img
//                   src={item.image}
//                   alt={item.name}
//                   style={itemImageStyle}
//                 />
//                 <div style={itemDetailsStyle}>
//                   <span style={itemNameStyle}>{item.name}</span>
//                   <span style={itemDescriptionStyle}>{item.description}</span>
//                   <span style={itemAdviceStyle}>
//                     Special Note: {item.advice}
//                   </span>
//                   <span style={itemPriceStyle}>
//                     Price: Rs {item.price} x {item.quantity} = Rs{' '}
//                     {item.price * item.quantity}
//                   </span>
//                   <div style={buttonGroupStyle}>
//                     <button
//                       onClick={() => removeItem(item.id)}
//                       style={removeButtonStyle}
//                     >
//                       Remove
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <p style={emptyBasketStyle}>Your basket is empty.</p>
//           )}
//         </div>

//         {editModel && (
//           <EditItemsModel
//             items={items}
//             onClose={handleEditModel}
//             handleEditModelClose={handleEditModelClose}
//             selecteditems={selecteditems}
//             setSelectedItems={setSelectedItems}
//             handleEdit={handleEdit}
//             setEditModel={setEditModel}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// // Styles
// const modalBackdropStyle = {
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: 'rgba(0,0,0,0.7)',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   zIndex: 1000,
// };

// const modalContentStyle = {
//   backgroundColor: 'white',
//   borderRadius: '12px',
//   padding: '30px',
//   width: '90%',
//   maxWidth: '600px',
//   maxHeight: '80vh',
//   overflowY: 'auto',
//   boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
// };

// const closeButtonStyle = {
//   backgroundColor: '#ff4d4f',
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   padding: '10px 20px',
//   fontSize: '18px',
//   position: 'absolute',
//   top: '20px',
//   right: '20px',
// };

// const basketItemsStyle = {
//   marginTop: '20px',
//   maxHeight: '60vh',
//   overflowY: 'auto',
// };

// const basketItemStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   marginBottom: '20px',
//   padding: '10px',
//   borderBottom: '1px solid #ddd',
//   flexWrap: 'wrap',
// };

// const itemImageStyle = {
//   width: '100px',
//   height: '100px',
//   objectFit: 'cover',
//   borderRadius: '8px',
//   marginRight: '20px',
//   flex: '0 0 100px',
// };

// const itemDetailsStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   flex: 1,
//   minWidth: '200px',
// };

// const itemNameStyle = {
//   fontSize: '20px',
//   fontWeight: 'bold',
//   marginBottom: '8px',
//   color: '#333',
// };

// const itemDescriptionStyle = {
//   fontSize: '16px',
//   marginBottom: '8px',
//   color: '#666',
// };

// const itemAdviceStyle = {
//   fontSize: '16px',
//   marginBottom: '8px',
//   color: '#555',
// };

// const itemPriceStyle = {
//   fontSize: '18px',
//   fontWeight: '600',
//   marginBottom: '12px',
//   color: '#007BFF',
// };

// const buttonGroupStyle = {
//   display: 'flex',
//   gap: '10px',
//   flexWrap: 'wrap',
// };

// const removeButtonStyle = {
//   backgroundColor: '#ff4d4f',
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   padding: '10px 15px',
//   fontSize: '16px',
// };

// const clearBacket = {
//   backgroundColor: 'red',
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   padding: '10px 20px',
//   fontSize: '16px',
//   fontWeight: '600',
//   flex: '1 1 auto',
//   margin: '0 5px',
// };

// const GenrateBill = {
//   backgroundColor: 'green',
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
//   padding: '10px 20px',
//   fontSize: '16px',
//   fontWeight: '600',
//   flex: '1 1 auto',
//   margin: '0 5px',
// };

// const modalTitleStyle = {
//   fontSize: '30px',
//   fontWeight: 'bold',
//   color: '#7d1a74',
//   textAlign: 'center',
// };

// const emptyBasketStyle = {
//   fontSize: '32px',
//   color: 'black',
//   textAlign: 'center',
//   fontWeight: '800',
// };

// export default BasketModals;




import React, { useContext, useEffect, useState } from 'react';
import { BasketContexts } from './BasketContexts';
// import EditItemsModel from './EditItemsModel';
// import { BasketContext } from './BasketContext';

const BasketModals = ({handleToggleBasket, tableNumber, setBasket}) => {

//   const { basket, table, setTable, clearBasketFun, GenrateBillFun, hotelId, hotelName  } = useContext(BasketContext);
  const { clearBasketFun, removeItem, GenrateBillFun, editFun  } = useContext(BasketContexts);

console.log("tableNumber------BasketModals-------->",tableNumber)

//   const [filterData, setFilterData] = useState([]);

//   useEffect(() => {
//     const filteredTables = table.filter(item => item.id === currentTable.id);
//     setFilterData(filteredTables[0].OrderDetails);
//   }, [table, currentTable]);

//   const removeItem = (id) => {
//     const newdata = filterData.filter((item) => item.id !== id);
//     const newOrderDetails = currentTable.OrderDetails.filter((item) => item.id !== id);
  
//     setTable((prevState) =>
//       prevState.map((tableItem) =>
//         tableItem.id === currentTable.id
//           ? { ...tableItem, OrderDetails: newdata }
//           : tableItem
//       )
//     );
//   };

const onClose = () =>{
    setBasket(false)
}



  return (
    <div style={modalBackdropStyle}>
      <div style={modalContentStyle}>
        <button onClick={onClose} style={closeButtonStyle}>
        {/* <button onClick={handleToggleBasket} style={closeButtonStyle}> */}
          Close
        </button>

        <div style={{ border: '0px solid red' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <span style={modalTitleStyle}>Your Basket</span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: '20px 0px' }}>
            <button
              onClick={clearBasketFun}
              style={clearBacket}
            >
              Clear Basket
            </button>

            <button
              onClick={GenrateBillFun}
              style={GenrateBill}
            >
              CheckOut
            </button>
          </div>
        </div>

        <div style={basketItemsStyle}>
          {
            tableNumber.OrderDetails.map((item) => (
              <div key={item.id} style={basketItemStyle}>
                <img
                  src={item.image}
                  alt={item.name}
                  style={itemImageStyle}
                />
                <div style={itemDetailsStyle}>
                  <span style={itemNameStyle}>{item.iteM_QTY_NAME}</span>
                  <span style={itemDescriptionStyle}>{item.description}</span>
                  <span style={itemAdviceStyle}>
                    Special Note: {item.remark}
                  </span>
                  <span style={itemPriceStyle}>
                    Price: Rs {item.iteM_RATE} x {item.quantity} = Rs{' '}
                    {item.iteM_RATE * item.quantity}
                  </span>
                  <div style={buttonGroupStyle}>

                    <button
                      onClick={() => editFun(item)}
                      style={editButtonStyle}
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => removeItem(item.iteM_ID)}
                      style={removeButtonStyle}
                    >
                      Remove
                    </button>

                  </div>
                </div>
              </div>
            ))
}
        </div>

        {/* {editModel && (
          <EditItemsModel
            items={items}
            onClose={handleEditModel}
            handleEditModelClose={handleEditModelClose}
            selecteditems={selecteditems}
            setSelectedItems={setSelectedItems}
            handleEdit={handleEdit}
            setEditModel={setEditModel}
          />
        )} */}
      </div>
    </div>
  );
  
};

// Styles
const modalBackdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: 'white',
  borderRadius: '12px',
  padding: '30px',
  width: '90%',
  maxWidth: '600px',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
};

const closeButtonStyle = {
  backgroundColor: '#ff4d4f',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '18px',
  position: 'absolute',
  top: '20px',
  right: '20px',
};

const basketItemsStyle = {
  marginTop: '20px',
  maxHeight: '60vh',
  overflowY: 'auto',
};

const basketItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  padding: '10px',
  borderBottom: '1px solid #ddd',
  flexWrap: 'wrap',
};

const itemImageStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'cover',
  borderRadius: '8px',
  marginRight: '20px',
  flex: '0 0 100px',
};

const itemDetailsStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: '200px',
};

const itemNameStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: '#333',
};

const itemDescriptionStyle = {
  fontSize: '16px',
  marginBottom: '8px',
  color: '#666',
};

const itemAdviceStyle = {
  fontSize: '16px',
  marginBottom: '8px',
  color: '#555',
};

const itemPriceStyle = {
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '12px',
  color: '#7d1a74',
};

const buttonGroupStyle = {
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
};

const removeButtonStyle = {
  backgroundColor: '#ff4d4f',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 15px',
  fontSize: '16px',
};

const editButtonStyle = {
  backgroundColor: '#007BFF',

  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 15px',
  fontSize: '16px',
};

const clearBacket = {
  backgroundColor: 'red',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: '600',
  flex: '1 1 auto',
  margin: '0 5px',
};

const GenrateBill = {
  backgroundColor: 'green',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: '600',
  flex: '1 1 auto',
  margin: '0 5px',
};

const modalTitleStyle = {
  fontSize: '30px',
  fontWeight: 'bold',
  color: '#7d1a74',
  textAlign: 'center',
};

const emptyBasketStyle = {
  fontSize: '32px',
  color: 'black',
  textAlign: 'center',
  fontWeight: '800',
};

export default BasketModals;


