// import React from 'react';
// import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
// import Home from './Menu/Home';
// import Menu from './Menu/Menu';
// import Dine_Table from './Menu/Dine_Table';
// import FoodItems from './Menu/FoodItems';
// import { BasketProvider } from './Menu/BasketContext';
// import CategoryList from './Menu/CategoryList';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import QRCodeGenerator from './Menu/QRCodeGenerator';
// import Main from './Menu/Main';

// function App() {
  
//   return (
//     <Router>
//     <BasketProvider>
//       <Routes>
//         <Route path="/" element={<QRCodeGenerator />} />
//         <Route path="/Home" element={<Home />} />
//         <Route path="/dinetable" element={<Dine_Table />} />
//         <Route path="/menu" element={<Menu />} />
//         <Route path="/fooditems" element={<FoodItems />} />
//         <Route path="/CategoryList" element={<CategoryList />} />
//         {/* <Route path="/Main" element={<Main />} />  */}
//       </Routes>
//       <ToastContainer
// position="top-center"
// autoClose={2000}
// hideProgressBar={true}
// newestOnTop={false}
// closeOnClick
// rtl={false}
// pauseOnFocusLoss
// draggable
// // pauseOnHover
// theme="dark"
// />
//     </BasketProvider>
//     </Router>
//   );
// }

// export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
// import Home from './Menu/Home';
// import Menu from './Menu/Menu';
// import Dine_Table from './Menu/Dine_Table';
// import FoodItems from './Menu/FoodItems';
// import { BasketProvider } from './Menu/BasketContext';
// import CategoryList from './Menu/CategoryList';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import QRCodeGenerator from './Menu/QRCodeGenerator';
// import Main from './NewTry/Main';
// import { BasketProviders } from './NewTry/BasketContexts';
// import CategoryLists from './NewTry/CategoryLists';
// import FoodItemss from './NewTry/FoodItemss';
// import Demo from './Demo';

// function App() {
  
//   return (
//     <Router>
//     {/* <BasketProviders> */}
//       <Routes>
//          <Route path="/Demo" element={<Demo />} />
//          {/* <Route path="/" element={<QRCodeGenerator />} />
//          <Route path="/CategoryLists" element={<CategoryLists />} />
//          <Route path="/FoodItemss" element={<FoodItemss />} /> */}
//       </Routes>
//       <ToastContainer
// position="top-center"
// autoClose={2000}
// hideProgressBar={true}
// newestOnTop={false}
// closeOnClick
// rtl={false}
// pauseOnFocusLoss
// draggable
// // pauseOnHover
// theme="dark"
// />
//     {/* </BasketProviders> */}
//     </Router>
//   );
// }

// export default App;



import React from 'react';
import { BrowserRouter , Route, Switch, Routes } from 'react-router-dom';
import Home from './Menu/Home';
import Menu from './Menu/Menu';
import Dine_Table from './Menu/Dine_Table';
import FoodItems from './Menu/FoodItems';
import { BasketProvider } from './Menu/BasketContext';
import CategoryList from './Menu/CategoryList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCodeGenerator from './Menu/QRCodeGenerator';
import Main from './NewTry/Main';
import { BasketProviders } from './NewTry/BasketContexts';
import CategoryLists from './NewTry/CategoryLists';
import FoodItemss from './NewTry/FoodItemss';

function App() {
  
  return (
    <BrowserRouter>
    <BasketProviders>
      <Routes>
      {/* <Route path="/" element={<QRCodeGenerator />} />
         <Route path="/CategoryLists" element={<CategoryLists />} />
         <Route path="/FoodItemss" element={<FoodItemss />} /> */}

         {/* <Route path="/" element={<QRCodeGenerator />} /> */}
         <Route path="/" element={<CategoryLists />} />
         <Route path="/FoodItemss" element={<FoodItemss />} />

      </Routes>
      <ToastContainer
position="top-center"
autoClose={2000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
// pauseOnHover
theme="dark"
/>
    </BasketProviders>
    </BrowserRouter>
  );
}

export default App;

