// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const Home = () => {
//   const navigate = useNavigate();

//   const navigateToMenu = () => {
//     navigate('/dinetable');
//   };

//   return (
//     <div style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100vh', // Make sure the container takes full viewport height
//       margin: 0, // Remove default margin
//       // border: '1px solid red' // Optional, for debugging purposes
//     }}>
//       <button onClick={navigateToMenu} style={{padding:'20px 50px', fontWeight:'800', borderRadius:20}}>Take Order</button>
//     </div>
//   );
// };

// export default Home;



import React, { useState } from 'react';
import backgroundImage from '../images/backgroundImage.jpg'
import { BsFillBasket2Fill } from 'react-icons/bs';
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Home = () => {

    const navigate = useNavigate();


    const checkEmail = ()=> /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    const checkPassword = ()=> password.length > 6

  const login = () => {

    // const test = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    // console.log("test------->",test)

    if(!checkEmail()){
      toast.success('Please enter valid email')
    }
    if(!checkPassword()){
      toast.success('Please enter valid password')
    }
    
if(checkEmail() && checkPassword()){

  toast.success('Login Successful')
  navigate('/dinetable');
}
  };


const [email,setEmail] = useState('shubham@gmail.com')

const [password,setPassword] = useState('shubham@gmail.com')

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    console.log("first")
    setPasswordVisible(!passwordVisible);
  };
  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <h2 style={styles.welcomeText}>Welcome</h2>
        <p style={styles.descriptionText}>Join Our Unique Platform, Explore a New Experience</p>
        <button style={styles.registerButton}>REGISTER</button>
      </div>
      <div style={styles.rightPanel}>
        <h2 style={styles.signInText}>Sign In</h2>

<div style={{border : '0px solid red'}}>
<div  style={styles.inputField}>

<input value={email} 
 type="email" 
 placeholder="Email" 
 onChange={(e)=> setEmail(e.target.value)}
 style={styles.input} 
 />
</div>
<div  style={styles.inputField}>

<input value={password}  
 placeholder="Password" 
 type={passwordVisible ? 'text' : 'password'} 
 onChange={(e)=> setPassword(e.target.value)}
 style={styles.inputPassword} 
 />
{
passwordVisible? (
<HiEye style={styles.basketIcon} onClick={togglePasswordVisibility}/>
) : (
<HiEyeOff style={styles.basketIcon} onClick={togglePasswordVisibility}/>

)
}

</div>
</div>

       
        <div style={styles.rememberMeSection}>
         
         <div style={styles.checkboCon}>
         <input type="checkbox" id="rememberMe" style={styles.checkbox} />
         <label htmlFor="rememberMe" style={styles.label}>Remember me</label>
         </div>

          <a href="#" style={styles.forgotPassword}>Forgot password?</a>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}  onClick={login} >
        <button style={styles.loginButton}>LOGIN</button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  leftPanel: {
    backgroundColor: 'rgba(125, 26, 116, 0.9)', // Add opacity to see the background image
    padding: '40px',
    borderRadius: '20px 0 0 20px',
    color: 'white',
    textAlign: 'center',
    width: '350px',
    border : '0px solid white',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  welcomeText: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  descriptionText: {
    fontSize: '1rem',
    marginBottom: '40px',
  },
  registerButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid white',
    borderRadius: '25px',
    padding: '10px 20px',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  rightPanel: {
    backgroundColor: 'white', // Add opacity to see the background image
    padding: '40px',
    borderRadius: '0 20px 20px 0',
    width: '350px',
    // textAlign: 'center',
        border : '0px solid black',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  signInText: {
    fontSize: '2rem',
    color: '#7d1a74',
    marginBottom: '20px',
  },
  inputField: {
    width: '100%',
    // padding: '10px',
    margin: '10px 0',
    borderRadius: '25px',
    border: '2px solid #ccc',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none', 
    
  },
  input : {
    width: '90%',
    padding: '10px',
    borderRadius: '25px',
    border: '0px solid #ccc',
    outline: 'none', 

  },
  inputPassword : {
    width: '80%',
    padding: '10px',
    borderRadius: '25px',
    border: '0px solid #ccc',
    outline: 'none', 
    // outline: 'none', 

  },
  basketIcon : {

    color:'#7d1a74',
    fontSize: '25px',

  },
  rememberMeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95%',
    margin: '10px 0',
    fontSize: '0.9rem',
    border: '0px solid red',
  },
  checkboCon : {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    // marginRight: '5px',
  },
  label: {
    color: '#7d1a74',
  },
  forgotPassword: {
    color: '#7d1a74',
    textDecoration: 'none',
  },
  loginButton: {
    backgroundColor: '#7d1a74',
    color: 'white',
    border: 'none',
    borderRadius: '25px',
    padding: '10px 80px',
    fontSize: '1rem',
    cursor: 'pointer',
    marginTop: '20px',
    // border : '15px soild white'
  },
  
 

};



export default Home;